import { TableHeaders } from './../../../../shared/components/table/models/tableHeaders.model';
import {
  isLessThenToday,
  scheduleHandler
} from './../../../../shared/components/helpers/datePickerHandlers';
import { DATE_API_FORMAT } from '@/utils/date.util';
import moment from 'moment-timezone';

import { TopTableHeaders } from '../../models/cellRender.model';
import { CellContentType } from '@/shared/components/table/models';
import { DropLiveType, LocationType } from '@/shared/models/woSchedule.model';
import { setPoolDisabled } from '../../utils/set-chaiss-pool';
import { dropLiveOptions } from '../../models/selectsOptions';
import { i18n } from '@/i18n';

const detailPart: TopTableHeaders = {
  key: 'detail',
  label: '',
  opportunityСhange: false,
  maxColspan: 3,
  minColspan: 1
};

const equipmentPart: TopTableHeaders = {
  key: 'eq',
  label: i18n.tc('mainTable.headers.equipment'),
  class: 'equipment-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 6,
  minColspan: 1
};

const infoPart: TopTableHeaders = {
  key: 'info',
  label: i18n.tc('mainTable.headers.info'),
  class: 'info-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const locationPart: TopTableHeaders = {
  key: 'loc',
  label: i18n.tc('mainTable.headers.location'),
  class: 'location-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const geofencePart: TopTableHeaders = {
  key: 'geo',
  label: i18n.tc('mainTable.headers.geofence'),
  class: 'geofence-color',
  opportunityСhange: true,
  collapsed: false,
  maxColspan: 3,
  minColspan: 1
};

const savePart: TopTableHeaders = {
  key: 'save',
  label: '',
  opportunityСhange: false,
  maxColspan: 3,
  minColspan: 1
};

export const ImportTableTopTypes: { [key: string]: TopTableHeaders[] } = {
  unavailable: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'eta',
      label: '',
      opportunityСhange: false,
      maxColspan: 6,
      minColspan: 1
    },
    {
      key: 'sch',
      label: i18n.tc('mainTable.headers.schedule'),
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 6,
      minColspan: 1
    },
    { ...savePart }
  ],

  available: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    {
      key: 'lfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: i18n.tc('mainTable.headers.schedule'),
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 7,
      minColspan: 1
    },
    { ...savePart }
  ],

  delivery: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'rfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: i18n.tc('mainTable.headers.schedule'),
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 11,
      minColspan: 1
    },
    { ...savePart }
  ],

  return: [
    { ...detailPart },
    { ...equipmentPart },
    { ...infoPart },
    { ...locationPart },
    { ...geofencePart },
    {
      key: 'rfd',
      label: '',
      opportunityСhange: false,
      maxColspan: 2,
      minColspan: 1
    },
    {
      key: 'sch',
      label: i18n.tc('mainTable.headers.schedule'),
      class: 'schedule-color',
      opportunityСhange: true,
      collapsed: false,
      maxColspan: 11,
      minColspan: 1
    },
    {
      key: 'save',
      label: '',
      opportunityСhange: false,
      maxColspan: 3,
      minColspan: 1
    }
  ]
};

const detailHeaderPart = [
  {
    key: 'number',
    label: i18n.tc('mainTable.no'),
    tdClass: 'min-w-20'
  },
  {
    key: 'orderNumber',
    label: i18n.tc('mainTable.wo'),
    tdClass: 'min-w-100'
  },
  {
    key: 'billTo',
    label: i18n.tc('order.billTo'),
    tdClass: 'min-w-80'
  }
];

const equipmentHeaderPart: (pools) => TableHeaders[] = pools => [
  { key: 'containerNumber', label: i18n.tc('order.cont'), tdClass: 'min-w-80' },
  {
    key: 'shippingLine',
    label: i18n.tc('order.ssl'),
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-30'
  },
  {
    key: 'containerSize',
    label: i18n.tc('mainTable.sz'),
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-30'
  },
  {
    key: 'chassisNumber',
    label: i18n.tc('order.chassisNumber'),
    rendererKey: 'eq',
    visible: true,
    content: {
      type: CellContentType.Text,
      validators: { name: 'regular', value: '^[a-zA-Z]{4}[0-9]{6}$' },
      validatorMessage:
        'This field is a required and must have 4 letters and 6 digits'
    },
    onChange: item => setPoolDisabled(item, pools),
    tdClass: 'min-w-130'
  },
  {
    key: 'chassisPool',
    label: i18n.tc('order.chassisPool'),
    rendererKey: 'eq',
    visible: true,
    content: {
      type: CellContentType.List,
      list: pools,
      validators: { name: 'requiredIf', value: 'chassisNumber' },
      validatorMessage: 'This field is a required'
    },
    tdClass: 'min-w-80'
  },
  {
    key: 'bondEntry',
    label: i18n.tc('mainTable.bond'),
    rendererKey: 'eq',
    visible: true,
    tdClass: 'min-w-60'
  }
];

const infoHeaderPart = [
  {
    key: 'referenceNumber',
    label: i18n.tc('mainTable.ref'),
    tdClass: 'min-w-80'
  },
  {
    key: 'poNumber',
    label: i18n.tc('mainTable.po'),
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-80'
  },
  {
    key: 'masterBillOfLading',
    label: i18n.tc('mainTable.mbl'),
    rendererKey: 'info',
    visible: true,
    tdClass: 'min-w-90'
  }
];

const locationHeaderPart = [
  {
    key: 'pulloutLocation',
    label: i18n.tc('mainTable.pOut'),
    tdClass: 'min-w-80'
  },
  {
    key: 'deliveryLocation',
    label: i18n.tc('mainTable.dilv'),
    rendererKey: 'loc',
    tdClass: 'min-w-80'
  },
  {
    key: 'returnLocation',
    label: i18n.tc('mainTable.rtn'),
    rendererKey: 'loc',
    visible: true,
    tdClass: 'min-w-80'
  }
];

const geofenceHeaderPart = [
  {
    key: 'originGeoferce',
    label: i18n.tc('mainTable.orig'),
    tdClass: 'min-w-80'
  },
  {
    key: 'currGeoferce',
    label: i18n.tc('mainTable.curr'),
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-80'
  },
  {
    key: 'dest',
    label: i18n.tc('mainTable.dest'),
    rendererKey: 'geo',
    visible: true,
    tdClass: 'min-w-80'
  }
];

const saveHeaderPart = [
  {
    key: 'save',
    label: i18n.tc('common.save'),
    content: {
      type: CellContentType.Button,
      label: i18n.tc('common.save')
    },
    tdClass: 'min-w-60'
  },
  {
    key: 'remarks',
    label: i18n.tc('order.remarks'),
    content: {
      type: CellContentType.Text,
      skipValidation: true
    }
  }
];

function getDay(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('MM/DD/YY')
    : schedule;
}

function getTime(schedule) {
  return schedule
    ? moment(schedule, DATE_API_FORMAT).format('hh:mm')
    : schedule;
}

export const ImportTableTypes = pools => {
  return {
    unavailable: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,

      { key: 'eta', label: i18n.tc('order.vesselEta'), formatter: getDay },
      {
        key: 'lastFreeDay',
        label: i18n.tc('order.lfd'),
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          validators: true,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'avaDsc',
        label: i18n.tc('order.dsc'),
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaObl',
        label: i18n.tc('order.obl'),
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaCus',
        label: i18n.tc('order.cus'),
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },
      {
        key: 'avaOth',
        label: i18n.tc('order.oth'),
        content: {
          type: CellContentType.Checkbox
        },
        tdClass: 'min-w-40'
      },

      {
        key: 'pulloutSchedule',
        label: i18n.tc('mainTable.pOutSDate'),
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutScheduleTime',
        label: i18n.tc('mainTable.time'),
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PULLOUT,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: i18n.tc('mainTable.dilvSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: i18n.tc('mainTable.time'),
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    available: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,

      {
        key: 'lastFreeDay',
        label: i18n.tc('order.lfd'),
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          validators: true,
          skipValidation: true
        },
        tdClass: 'min-w-130',
        handler: isLessThenToday(
          'LFD is earlier than current date. Are you sure?'
        ),

        onChange: item => {
          item.lastFreeDayCount = moment(
            item.lastFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      {
        key: 'lastFreeDayCount',
        label: i18n.tc('mainTable.lfdDay'),
        tdClass: 'min-w-40'
      },

      {
        key: 'pulloutSchedule',
        label: i18n.tc('mainTable.pOutSDate'),
        formatter: getDay,
        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutScheduleTime',
        label: i18n.tc('mainTable.time'),
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PULLOUT,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: i18n.tc('mainTable.dilvSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,

        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: i18n.tc('mainTable.time'),
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: i18n.tc('mainTable.dropLive'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.List,
          list: dropLiveOptions
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    delivery: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,
      ...geofenceHeaderPart,
      {
        key: 'returnFreeDay',
        label: i18n.tc('order.rfd'),
        disabled: true,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130',
        onChange: item => {
          item.returnFreeDayCount = moment(
            item.returnFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      {
        key: 'returnFreeDayCount',
        label: i18n.tc('mainTable.rfdDay'),
        tdClass: 'min-w-40'
      },

      {
        key: 'pulloutActualIn',
        label: 'P/OUT Date',
        disabled: true,
        formatter: getDay,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pulloutActualInTime',
        label: i18n.tc('mainTable.time'),
        disabled: true,
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
        // formatter: getTime
      },
      {
        key: 'pulloutDriver',
        label: i18n.tc('mainTable.driverId'),
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: i18n.tc('mainTable.dilvSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,

        handler: scheduleHandler,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: i18n.tc('mainTable.time'),
        formatter: getTime,
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.DELIVERY,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: i18n.tc('mainTable.dropLive'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.List,
          list: dropLiveOptions
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'emptyConfirmation',
        label: i18n.tc('order.emptyCFM'),
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130',
        formatter: getDay
      },
      {
        key: 'pickupSchedule',
        label: i18n.tc('mainTable.pUSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupScheduleTime',
        label: i18n.tc('mainTable.time'),
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PICKUP,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      ...saveHeaderPart
    ],

    return: [
      ...detailHeaderPart,
      ...equipmentHeaderPart(pools),
      ...infoHeaderPart,
      ...locationHeaderPart,
      ...geofenceHeaderPart,
      {
        key: 'returnFreeDay',
        label: i18n.tc('order.rfd'),
        disabled: true,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        onChange: item => {
          item.returnFreeDayCount = moment(
            item.returnFreeDay,
            DATE_API_FORMAT
          ).diff(moment().startOf('day'), 'days');

          return { ...item };
        }
      },
      { key: 'returnFreeDayCount', label: i18n.tc('mainTable.rfdDay') },

      {
        key: 'pulloutSchedule',
        label: i18n.tc('mainTable.pOutSDate'),
        disabled: true,
        formatter: getDay,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliverySchedule',
        label: i18n.tc('mainTable.dilvSDate'),
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        tdClass: 'min-w-130'
      },
      {
        key: 'deliveryScheduleTime',
        label: i18n.tc('mainTable.time'),
        disabled: true,
        rendererKey: 'sch',
        visible: true,
        formatter: getTime,
        tdClass: 'min-w-130'
      },
      {
        key: 'dropLive',
        label: i18n.tc('mainTable.dropLive'),
        rendererKey: 'sch',
        visible: true,
        disabled: true,
        tdClass: 'min-w-130'
      },
      {
        key: 'emptyConfirmation',
        label: i18n.tc('order.emptyCFM'),
        rendererKey: 'sch',
        visible: true,
        tdClass: 'min-w-130',
        formatter: getDay
      },
      {
        key: 'pickupSchedule',
        label: i18n.tc('mainTable.pUSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupScheduleTime',
        label: i18n.tc('mainTable.time'),
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'pickupDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.PICKUP,
          isDisabledBy: DropLiveType.LIVE,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnSchedule',
        label: i18n.tc('mainTable.rtnSDate'),
        rendererKey: 'sch',
        visible: true,
        formatter: getDay,
        content: {
          type: CellContentType.Date,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnScheduleTime',
        label: i18n.tc('mainTable.time'),
        rendererKey: 'sch',
        formatter: getTime,
        visible: true,
        content: {
          type: CellContentType.Time,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },
      {
        key: 'returnDriver',
        label: i18n.tc('mainTable.driverId'),
        rendererKey: 'sch',
        visible: true,
        content: {
          type: CellContentType.Driver,
          locationType: LocationType.RETURN,
          skipValidation: true
        },
        tdClass: 'min-w-130'
      },

      {
        key: 'save',
        label: i18n.tc('common.save'),
        content: {
          type: CellContentType.Button,
          label: i18n.tc('common.save')
        },
        tdClass: 'min-w-60'
      },
      {
        key: 'complete',
        label: i18n.tc('common.complete'),
        content: {
          type: CellContentType.Button,
          label: i18n.tc('common.complete')
        },
        tdClass: 'min-w-60'
      },
      {
        key: 'remarks',
        label: i18n.tc('order.remarks'),
        content: {
          type: CellContentType.Text,
          skipValidation: true
        }
      }
    ]
  };
};
